<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                :header="header"
                :isParentGuExist="isParentGuExist"
                :isHeadGu="isHeadGu"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
                @downloadBatchReportsPaid="downloadBatchReportsPaid"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
            </div>
            <div class="right-content">
                <c-budget-forms-data-transfer
                    v-if="!isGkkpMode && !(progress < 100) && variantAttribute"
                    :header="header"
                    :lng="lng"
                    :getDataTransferText="getDataTransferText"
                    :getErrText="getErrText"
                    :makeToast="makeToast"
                    @reloadTable="loadDatas"
                />
                <div class="filter-actions filter-actions-flex">
                    <c-budget-forms-copy-data 
                        v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                        :budgetForm="budgetForm"
                        :header="header"
                        :isLoad="isLoad"
                        @keyPress="keyPress"
                    />
                    <b-button :style="{ 'minWidth': '110px' }" variant="success" @click="prepareForSave" :disabled="isLoad || !variantAttribute || load">{{ getCommonText('save') }}</b-button>
                </div>
            </div>
        </div>

        <breadcrumbs-filter :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td class="td-numbering"></td>
                    <td class="td-numbering table-success">1</td>
                    <td class="td-numbering table-danger">2</td>
                    <td class="td-numbering table-info">3</td>
                    <td class="td-numbering table-primary">4</td>
                    <td class="td-numbering table-warning">5</td>
                    <td class="td-numbering table-success">6</td>
                    <td class="td-numbering table-danger">7</td>
                    <td class="td-numbering table-primary">8</td>
                    <td class="td-numbering table-info">9</td>
                    <td class="td-numbering table-warning">10</td>
                    <td class="td-numbering table-primary">11</td>
                    <td class="td-numbering table-success">12</td>
                    <td class="td-numbering table-danger">13</td>
                    <td class="td-numbering table-info">14</td>
                    <td class="td-numbering table-primary">15</td>
                    <td class="td-numbering table-warning">16</td>
                    <td class="td-numbering table-success">17</td>
                    <td class="td-numbering table-danger">18</td>
                    <td class="td-numbering table-primary">19</td>
                    <td class="td-numbering table-success">20</td>
                    <td class="td-numbering"></td>
                </template>
                <template #thead-top="data">
                    <b-tr>
                        <b-th colspan="1" rowspan="2"></b-th>
                        <b-th colspan="5" rowspan="2"></b-th>
                        <b-th colspan="12" class="text-center">{{ getFormText('scholarship_upgrade') }}</b-th>
                        <b-th colspan="2" rowspan="2"></b-th>
                        <b-th colspan="2" rowspan="2"></b-th>
                    </b-tr>
                    <b-tr>
                        <b-th colspan="3" class="text-center">{{ getFormText('merit_scholarship') }}</b-th>
                        <b-th colspan="3" class="text-center">{{ getFormText('orphan_fin_aid') }}</b-th>
                        <b-th colspan="3" class="text-center">{{ getFormText('disability_scholarship') }}</b-th>
                        <b-th colspan="3" class="text-center">{{ getFormText('gov_scholarship') }}</b-th>
                    </b-tr>
                </template>
                <template #head(action)>
                    <div class="text-center">
                        <b-form-checkbox 
                            v-model="selectAll" 
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i 
                            :title="getCommonText('del_selected')"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? getCommonText('clear_all') : getCommonText('clear')}`)"/>
                    </div>
                </template>
                <template #cell(action)="data">
                    <b-form-checkbox 
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(name_ru)="data">
                    <div class="scroll-container">{{data.item.text}}</div>
                </template>
                <template #cell(avg_annual_contingent0)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.avg_annual_contingent0"
                                  @change="v => data.item.avg_annual_contingent0 = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'avg_annual_contingent0', data.item.avg_annual_contingent0, 1)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(amount_months)="data">
                    <div v-if="variantAttribute" :class="{ 'error': data.item.amount_months > 12.0 }">
                        <b-form-input class="text-right"
                                      :value="data.item.amount_months"
                                      @change="v => data.item.amount_months = v"
                                      @keyup.enter.exact="keyup13"
                                      @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                      @blur="inputFixedVldtn(data.item, 'amount_months', data.item.amount_months, 1)">
                        </b-form-input>
                        <template v-if="data.item.amount_months > 12.0">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">{{ getCommonText("not_valid") }}</p>
                                <p>{{ getCommonText("month_count") }}</p>
                            </div>
                        </template>
                    </div>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(state_scholarship)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.state_scholarship"
                                  @change="v => data.item.state_scholarship = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'state_scholarship', data.item.state_scholarship, 3)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(costs)="data">
                    <div class="text-right">
                        {{ $n(data.item.costs) }}
                    </div>
                </template>
                <template #cell(avg_annual_contingent1)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.avg_annual_contingent1"
                                  @change="v => data.item.avg_annual_contingent1 = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'avg_annual_contingent1', data.item.avg_annual_contingent1, 1)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(percentage_increase1)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.percentage_increase1"
                                  @change="v => data.item.percentage_increase1 = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'percentage_increase1', data.item.percentage_increase1, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(summa1)="data">
                    <div class="text-right">
                        {{ $n(data.item.summa1) }}
                    </div>
                </template>
                <template #cell(avg_annual_contingent2)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.avg_annual_contingent2"
                                  @change="v => data.item.avg_annual_contingent2 = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'avg_annual_contingent2', data.item.avg_annual_contingent2, 1)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(percentage_increase2)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.percentage_increase2"
                                  @change="v => data.item.percentage_increase2 = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'percentage_increase2', data.item.percentage_increase2, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(summa2)="data">
                    <div class="text-right">
                        {{ $n(data.item.summa2) }}
                    </div>
                </template>
                <template #cell(avg_annual_contingent3)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.avg_annual_contingent3"
                                  @change="v => data.item.avg_annual_contingent3 = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'avg_annual_contingent3', data.item.avg_annual_contingent3, 1)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(percentage_increase3)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.percentage_increase3"
                                  @change="v => data.item.percentage_increase3 = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'percentage_increase3', data.item.percentage_increase3, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(summa3)="data">
                    <div class="text-right">
                        {{ $n(data.item.summa3) }}
                    </div>
                </template>
                <template #cell(avg_annual_contingent4)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.avg_annual_contingent4"
                                  @change="v => data.item.avg_annual_contingent4 = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'avg_annual_contingent4', data.item.avg_annual_contingent4, 1)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(size_scholarship)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :value="data.item.size_scholarship"
                                  @change="v => data.item.size_scholarship = v"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^\\d*\\.?\\d{0,9}$')"
                                  @blur="inputFixedVldtn(data.item, 'size_scholarship', data.item.size_scholarship, 2)">
                    </b-form-input>
                    <div v-else>{{ data.value }}</div>
                </template>
                <template #cell(summa4)="data">
                    <div class="text-right">
                        {{ $n(data.item.summa4) }}
                    </div>
                </template>
                <template #cell(all_size_scholarship)="data">
                    <div class="text-right">
                        {{ $n(data.item.all_size_scholarship) }}
                    </div>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">
                        {{ $n(data.item.total) }}
                    </div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onFilesClick(data.item)"
                    >({{data.item.files}})</span>
                </template>
                <template #cell(more)="data">
                    <div class="text-center">
                        <i 
                            :title="getCommonText('clear_rec')" 
                            class="icon icon-clear table-remove" 
                            v-if="variantAttribute" 
                            @click="deleteItem(`${getCommonText('clear_rec')}?`, data.item, data.index)"
                        ></i>
                    </div>
                </template>

                <template #bottom-row="data">
                    <td class="text-right" colspan="19">{{ getCommonText('total') }}</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
        </div>
        <files-updown :header="header"
                      :variant-attribute="variantAttribute"
                      :load="load"
                      @getFiles="getFiles"
                      @getNewFiles="getNewFiles($event)"
                      @delFile="delFile($event)"
                      @onFilePreview="onFilePreview"
                      ref="fileUpdown"
        ></files-updown>
        <modal-files-management-nodecode
            ref="modalFilesManagement"
            :variant-attribute="variantAttribute"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            @fileUpload="fileUpload"
            @toggleIsAdd="toggleIsAdd($event)"
            />
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
import ModalFilesManagementNodecode from './components/modal-files-management-nodecode.vue';
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
import FormsHandlerMixin1 from "./mixins/forms-handler-mixin-1";
import FormsDownloadReprt from "./components/forms-download-reprt.vue";
import CBudgetFormsDataTransfer from './components/budget-forms-data-transfer.vue';

export default {
    name: 'Form02-324',
    components: { 
        BudgetHeader, 
        FilesUpdown, 
        BudgetFormsList, 
        FormsDownloadReprt, 
        BreadcrumbsFilter, 
        ModalFilesManagementNodecode, 
        CBudgetFormsCopyData,
        CBudgetFormsDataTransfer
    },
    mixins: [FormsHandlerMixin1],
    data() {
        return {
            form: {
                code: '02-324',
                name_kk: '',
                name_ru: 'Расчет расходов на выплату стипендии студентам, магистрантам, интернам, слушателям подготовительного отделения и резидентуры, докторантам, обучающимся в организациях образования и здравоохранения, культуры и спорта'
            },
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dict: null,
            files: null,
            load: true,
            openDisabled: false,
            mrp: 0,
            dictCivilScholars: [],
            isLoad: false,
            row_files: [],
            isAdd: false,
            rowId: false,
            newRowStartId: -1,
        };
    },
    async mounted() {
        await this.loadCivilScholars();
    },
    methods: {
        deleteItem(msg, row = false) {
            if (!this.variantAttribute || this.isLoad 
                || (!row && this.budgetForm.findIndex(itm => itm.itemToDelete) === -1)) return;
            this.$bvModal.msgBoxConfirm(
                msg,
                {
                    title: this.getCommonText('confirm'),
                    size: 'lg',
                    buttonSize: 'sm',
                    okVariant: 'danger',
                    okTitle: this.getCommonText('yes'),
                    cancelTitle: this.getCommonText('cancel'),
                    footerClass: 'p-2',
                    hideHeaderClose: false,
                    centered: true
                })
                .then(value => {
                    if (value) {
                        if (row) {
                            row.avg_annual_contingent0 = 0;
                            row.amount_months = 0;
                            row.state_scholarship = 0;
                            row.avg_annual_contingent1 = 0;
                            row.percentage_increase1 = 0;
                            row.avg_annual_contingent2 = 0;
                            row.percentage_increase2 = 0;
                            row.avg_annual_contingent3 = 0;
                            row.percentage_increase3 = 0;
                            row.avg_annual_contingent4 = 0;
                            row.size_scholarship = 0;
                        } else {
                            this.budgetForm.forEach(item => {
                                if (item.itemToDelete) {
                                    item.avg_annual_contingent0 = 0;
                                    item.amount_months = 0;
                                    item.state_scholarship = 0;
                                    item.avg_annual_contingent1 = 0;
                                    item.percentage_increase1 = 0;
                                    item.avg_annual_contingent2 = 0;
                                    item.percentage_increase2 = 0;
                                    item.avg_annual_contingent3 = 0;
                                    item.percentage_increase3 = 0;
                                    item.avg_annual_contingent4 = 0;
                                    item.size_scholarship = 0;
                                };
                            });
                        }
                        this.prepareForSave();
                    }
                })
                .catch(error => {
                    this.makeToast('danger',  this.getErrText('on_del'), error.toString());
                });
        }, // очистить значения строки

        getFiles(data) {
            this.files = data;
        },

        keyPress: function (event, pattern) {
            // const regex = new RegExp('^[0-9]+$');
            // const regex = new RegExp('^-?\\d*\\d{0,9}$');
            // const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        async loadDatas() {
            const that = this;

            that.load = true;
            that.budgetForm.splice(0);
            for (const item of that.dictCivilScholars) {
                const dataItem = {
                    id: this.newRowStartId,
                    code: item.code,
                    avg_annual_contingent0: 0,
                    amount_months: 0,
                    state_scholarship: 0,
                    avg_annual_contingent1: 0,
                    percentage_increase1: 0,
                    avg_annual_contingent2: 0,
                    percentage_increase2: 0,
                    avg_annual_contingent3: 0,
                    percentage_increase3: 0,
                    avg_annual_contingent4: 0,
                    size_scholarship: 0,
                    itemToDelete: false,
                };
                this.newRowStartId--;

                this.$set(dataItem, 'files', 0);

                try {
                    this.load = true;
                    const response = await fetch(`/api-py/get-new-row-files-form/${dataItem.id}/${JSON.stringify(this.header)}`);
                    const emptyRowFiles = await response.json();
                    this.$set(dataItem, 'row_files', emptyRowFiles);
                } catch (error) {
                    this.makeToast('danger', `${this.getErrText('bad_request')} itemUpdate`, error.toString());
                }
                Object.defineProperty(dataItem, 'text', {
                    get: () => {
                        if (this.lng) return item[`name_${this.lng}`];
                        return item.name_ru;
                    }
                });
                Object.defineProperty(dataItem, 'costs', {
                    get: function () {
                        const sum = parseFloat(dataItem.avg_annual_contingent0) * parseFloat(dataItem.state_scholarship);
                        return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
                    }
                });
                Object.defineProperty(dataItem, 'summa1', {
                    get: function () {
                        const sum = parseFloat(dataItem.avg_annual_contingent1) * parseFloat(dataItem.percentage_increase1);
                        return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
                    }
                });
                Object.defineProperty(dataItem, 'summa2', {
                    get: function () {
                        const sum = parseFloat(dataItem.avg_annual_contingent2) * parseFloat(dataItem.percentage_increase2);
                        return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
                    }
                });
                Object.defineProperty(dataItem, 'summa3', {
                    get: function () {
                        const sum = parseFloat(dataItem.avg_annual_contingent3) * parseFloat(dataItem.percentage_increase3);
                        return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
                    }
                });
                Object.defineProperty(dataItem, 'summa4', {
                    get: function () {
                        const sum = parseFloat(dataItem.avg_annual_contingent4) * parseFloat(dataItem.size_scholarship);
                        return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
                    }
                });
                Object.defineProperty(dataItem, 'all_size_scholarship', {
                    get: function () {
                        const sum = parseFloat(dataItem.costs) + parseFloat(dataItem.summa1) + parseFloat(dataItem.summa2) + parseFloat(dataItem.summa3) + parseFloat(dataItem.summa4);
                        return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
                    }
                });
                Object.defineProperty(dataItem, 'total', {
                    get: function () {
                        const sum = parseFloat(dataItem.all_size_scholarship) * parseFloat(dataItem.amount_months);
                        return parseFloat((Math.round(sum * 100) / 100).toFixed(2));
                    }
                });
                that.budgetForm.push(dataItem);
            }

            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form-with-row-attach-files/' + JSON.stringify(that.header));
                values = await response.json();
                if (response.status === 200) {
                    if (values.length === 0) {
                        that.load = false;
                        return;
                    }
                } else {
                    this.budgetForm.splice(0);
                    this.makeToast('danger', `${this.getErrText('bad_request')} loadDatas()`, `Error code: ${response.status}`);
                }
            } catch (error) {
                this.budgetForm.splice(0);
                that.makeToast('danger', `${this.getErrText('bad_request')} loadDatas()`, error.toString());
            }

            await values.forEach(val => {
                const itemExist = that.budgetForm.filter(function (item) {
                    if (item.code === val.civil_type) { return item; }
                });

                if (itemExist.length > 0) {
                    itemExist[0].id = val.id;
                    itemExist[0].avg_annual_contingent0 = val.avg_annual_contingent0;
                    itemExist[0].amount_months = val.amount_months;
                    itemExist[0].state_scholarship = val.state_scholarship;
                    itemExist[0].avg_annual_contingent1 = val.avg_annual_contingent1;
                    itemExist[0].percentage_increase1 = val.percentage_increase1;
                    itemExist[0].avg_annual_contingent2 = val.avg_annual_contingent2;
                    itemExist[0].percentage_increase2 = val.percentage_increase2;
                    itemExist[0].avg_annual_contingent3 = val.avg_annual_contingent3;
                    itemExist[0].percentage_increase3 = val.percentage_increase3;
                    itemExist[0].avg_annual_contingent4 = val.avg_annual_contingent4;
                    itemExist[0].size_scholarship = val.size_scholarship;
                    itemExist[0].files = val.files;
                    itemExist[0].row_files = val.row_files;
                }
            });
            that.load = false;
        },

        async loadCivilScholars() {
            try {
                this.dictCivilScholars = [];
                const response = await fetch('/api-py/dictionary/dict_civil_scholars/');
                const csList = await response.json();
                csList.forEach(cs => {
                    this.dictCivilScholars.push({
                        code: cs.code,
                        name_ru: cs.name_ru,
                        name_kk: cs.name_kk
                    });
                });
            } catch (error) {
                this.makeToast('danger', `${this.getErrText('bad_request')} loadCivilScholars()`, error.toString());
            }
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        prepareForSave() {
            const values = [];
            let error = false;
            for (const row of this.budgetForm) {
                if (parseFloat(row.amount_months) < 12.1) {
                    const item = Object.assign({}, this.header);
                    this.$set(item, 'id', row.id);
                    this.$set(item, 'civil_type', row.code);
                    this.$set(item, 'avg_annual_contingent0', parseFloat(row.avg_annual_contingent0));
                    this.$set(item, 'amount_months', parseFloat(row.amount_months));
                    this.$set(item, 'state_scholarship', parseFloat(row.state_scholarship));
                    this.$set(item, 'avg_annual_contingent1', parseFloat(row.avg_annual_contingent1));
                    this.$set(item, 'percentage_increase1', parseFloat(row.percentage_increase1));
                    this.$set(item, 'avg_annual_contingent2', parseFloat(row.avg_annual_contingent2));
                    this.$set(item, 'percentage_increase2', parseFloat(row.percentage_increase2));
                    this.$set(item, 'avg_annual_contingent3', parseFloat(row.avg_annual_contingent3));
                    this.$set(item, 'percentage_increase3', parseFloat(row.percentage_increase3));
                    this.$set(item, 'avg_annual_contingent4', parseFloat(row.avg_annual_contingent4));
                    this.$set(item, 'size_scholarship', parseFloat(row.size_scholarship));
                    this.$set(item, 'row_files', row.row_files);
                    this.$set(item, 'total', row.total);
                    values.push(item);
                } else {
                    error = true;
                }
            }
            if (error) {
                this.onFieldsValidationFailed();
                return;
            }
            if (values.length > 0) {
                this.save(values, error);
            } else {
                this.makeToast('warning', this.getErrText('msg'), this.getErrText('not_saved'));
            }
        },

        async save(values, error) {
            this.isLoad = true;
            try {
                const url = this.header.mode === 'gkkp' ? '/api-py/save-brform-gkkp/' : '/api-py/save-brform' + this.form.code + '/'
                const totalToSave = this.totalCalculation(values, 'total')
                this.$set(this.header, 'value', totalToSave);
                const response = await fetch(url + JSON.stringify(this.header), {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(values)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (this.files.length === 0) {
                        this.makeToast('warning', this.getErrText('warning'), this.getErrText('no_docs'));
                    }
                    this.selectAll = false;
                    this.makeToast('success', this.getErrText('msg'), this.getErrText('saved'));
                    this.deletingAgreementTotalResultHandler(result);
                    await this.loadDatas();
                } else {
                    this.selectAll = false;
                    await this.loadDatas();
                    throw this.getErrText('bad_data');
                }
            } catch (e) {
                this.makeToast('danger', this.getErrText('warning'), e.toString());
            } finally {
                this.isLoad = false;
            }
        }, // сохранение данных
    },
    computed: {
        total() {
            return this.totalCalculation(this.budgetForm, 'total');
        },

        getFormText() {
            return this.setFormText('form_02_324.');
        },

        tableFields() {
            if (this.$i18n.locale) {
                return [
                    {
                        key: 'action',
                        label: ' '
                    },
                    {
                        key: 'name_ru',
                        label: this.getFormText('scholar_list')
                    },
                    {
                        key: 'avg_annual_contingent0',
                        label: this.getFormText('avg_ann_cont')
                    },
                    {
                        key: 'amount_months',
                        label: this.getFormText('month_count')
                    },
                    {
                        key: 'state_scholarship',
                        label: this.getFormText('scholarship_amount')
                    },
                    {
                        key: 'costs',
                        label: this.getFormText('monthly_scholarship_expense')
                    },
                    {
                        key: 'avg_annual_contingent1',
                        label: this.getFormText('avg_ann_cont')
                    },
                    {
                        key: 'percentage_increase1',
                        label: this.getFormText('percent_upgrade')
                    },
                    {
                        key: 'summa1',
                        label: this.getFormText('monthly_sum_6_7')
                    },
                    {
                        key: 'avg_annual_contingent2',
                        label: this.getFormText('avg_ann_cont')
                    },
                    {
                        key: 'percentage_increase2',
                        label: this.getFormText('percent_upgrade')
                    },
                    {
                        key: 'summa2',
                        label: this.getFormText('monthly_sum_10_11')
                    },
                    {
                        key: 'avg_annual_contingent3',
                        label: this.getFormText('avg_ann_cont')
                    },
                    {
                        key: 'percentage_increase3',
                        label: this.getFormText('percent_upgrade')
                    },
                    {
                        key: 'summa3',
                        label: this.getFormText('monthly_sum_12_13')
                    },
                    {
                        key: 'avg_annual_contingent4',
                        label: this.getFormText('avg_ann_cont')
                    },
                    {
                        key: 'size_scholarship',
                        label: this.getFormText('scholarship_amount_2')
                    },
                    {
                        key: 'summa4',
                        label: this.getFormText('monthly_sum_14_15')
                    },
                    {
                        key: 'all_size_scholarship',
                        label: this.getFormText('scholarship_amount_total')
                    },
                    {
                        key: 'total',
                        label: this.getFormText('expense_total')
                    },
                    {
                        key: 'files',
                        label: this.getCommonText('files')
                    },
                    {
                        key: 'more',
                        label: ''
                    }
                ];
            };
            return [];
        },
    }
};
</script>
<style scoped>
    .scroll-container {
        display: block;
        width: 350px;
        max-height: 200px;
        overflow-y: auto;
        scroll-behavior: smooth;
    }
    .filter-actions-flex {
        display: flex;
    }
</style>